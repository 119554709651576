import React, {useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout'
import Head from '../components/head'
import {useAuth} from '../util/use-auth'

const Logout: React.FC = () => {
  const auth = useAuth()

  useEffect(() => {
    ;(async () => {
      try {
        // If there is a user, signOut which will set the user to null
        await auth?.signOut()
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  // Check if we are still loading
  if (!auth || auth.isAuthenticating) {
    return <></>
  }

  // We're loaded, but there is no user, just redirect
  if (!auth.user) {
    navigate('/')
    return <></>
  }

  // Default, pre-redirect
  return (
    <Layout>
      <Head title="Logout" />
      <div>
        <div className="Logout">Thanks! You should be automatically redirected</div>
      </div>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default Logout
